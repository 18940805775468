<template>
  <div id="merchant-page">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <h2>{{$t('CAMAs')}}</h2>
      </div>
      <div class="vx-col w-full lg:w-1/2 items-end">
        <div class="flex justify-between">
          <span class="font-semibold"></span>
          <span class="font-medium text-primary cursor-pointer">
            <vs-button
              @click="openManagerModal('add')"
              color="primary"
              icon-pack="feather"
              icon="icon-plus"
              size="large">
            </vs-button>
          </span>
        </div>
      </div>
    </div>

    <shipblu-table
      :sst="true"
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      @search="handleSearch"
      @keyup.enter.native="handleSearch"
      :search="$store.state.AppActiveUser.userRole !== 'head-of-fleet'"
      :data="customerAgents"
      :tableLoader="tableLoader"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
      </div>
      <template slot="thead">
        <shipblu-th>{{$t('Agent Name')}}</shipblu-th>
        <shipblu-th>{{$t('Phone Number')}}</shipblu-th>
        <shipblu-th>{{$t('HR ID')}}</shipblu-th>
        <shipblu-th>{{$t('Warehouse')}}</shipblu-th>
        <shipblu-th>{{$t('Zone Group')}}</shipblu-th>
        <shipblu-th>{{$t('Use Ringer')}}</shipblu-th>
        <shipblu-th>{{$t('Actions')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].name">
            {{ data[indextr].first_name }} {{ data[indextr].last_name }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].phone_number">
            {{ data[indextr].phone_number }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].hr_id">
            {{ data[indextr].customer_agent_persona.hr_id }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].primary_warehouse">
            {{ data[indextr].customer_agent_persona.primary_warehouse ? warehousesDic[data[indextr].customer_agent_persona.primary_warehouse].name : '' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].zone_group">
            {{ data[indextr].customer_agent_persona.zone_group ? data[indextr].customer_agent_persona.zone_group.name : '' }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].use_ringer">
            <div class="flex justify-center">
              <vs-switch disabled="true" v-model="data[indextr].customer_agent_persona.use_ringer"/>
            </div>
          </shipblu-td>
          
          <shipblu-td>
            <vs-dropdown
              vs-trigger-click
              class="dd-actions cursor-pointer"
              @click="selected = []"
            >
              <vs-button
                type="border"
                size="small"
                icon-pack="feather"
                icon="icon-more-horizontal"
                class="mr-2"
              >
              </vs-button>
              <vs-dropdown-menu style="width: 190px">

                <!-- ACTIONS FOR ON CREATED -->
                <vs-dropdown-item
                  @click="openManagerModal('edit', data[indextr])"
                >
                  <span class="flex items-center">
                    <feather-icon
                      icon="EditIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>{{$t('Edit')}}</span>
                  </span>
                </vs-dropdown-item>
                <vs-dropdown-item
                  @click="resetPassword(data[indextr])"
                >
                  <span class="flex items-center">
                    <feather-icon
                      icon="EditIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>{{$t('Change Password')}}</span>
                  </span>
                </vs-dropdown-item>
                <vs-dropdown-item
                  @click="deleteCustomerAgent(data[indextr])"
                >
                  <span class="flex items-center">
                    <feather-icon
                      icon="TrashIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>{{$t('Delete')}}</span>
                  </span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <add-customer-merchant-agent :type="type" :warehousesDic="warehousesDic" :warehouses="warehouses" :data="customerAgent" :addUserModal="addUserModal" @addUserModal="addUserModal = $event" @loadData="loadData" />
    <reset-password userRole="customer-merchant-agents" :resetPass="resetPass" :userData="userData" @loadData="loadData" @resetPass="resetPass = $event"></reset-password>
  </div>
</template>

<script>
import ResetPassword from './components/ResetPassword.vue'
import i18nData from '../../i18n/i18nData.js'
import common  from '@/assets/utils/common'
import { sendRequest }  from '../../http/axios/requestHelper.js'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'
import AddCustomerMerchantAgent from './components/AddCustomerMerchantAgent.vue'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      managerURL: 'api/v1/users/customer-merchant-agents/',
      selected: [],
      deleteData: {},
      customerAgents: [],
      addUserModal: false,
      type: '',
      addNewDataSidebar: false,
      customerAgent: {},
      offset: 0,
      totalRows: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      warehouses: [],
      searchVal: '',
      warehousesDic: {},
      resetPass: false,
      userData: {},
      searchInProgress: false,
      tableLoader: false,
      searchedValue: ' '
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-customer-agents`,
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadData()
    },
    '$route.params.warehouseID' () {
      common.manageLoadWarehouses(this.formatWarehouses, this)
    }
  },
  methods: {
    formatWarehouses (warehouses) {
      this.warehouses = warehouses.filter(item => !item.is_virtual)
      this.warehouses.forEach(warehouse => {
        this.warehousesDic[warehouse.id] = {
          name: warehouse.name,
          code: warehouse.code
        }
      })
      this.loadData()
    },
    handleSearch (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadCustomerAgentsSearch)
    },
    loadCustomerAgentsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadData()
    },
    loadData () {
      this.searchInProgress = true
      this.tableLoader = true
      this.$vs.loading()
      if (this.$store.state.AppActiveUser.userRole === 'head-of-fleet') {
        this.managerURL = `api/v1/warehouse/${this.$route.params.warehouseID}/agents/customer_merchant_agent/?offset=${this.offset}&limit=${this.maximumItems}`
      } else {
        this.managerURL =  `api/v1/users/customer-merchant-agents/?offset=${this.offset}&limit=${this.maximumItems}&search=${this.searchVal}`
      }
      sendRequest(true, false, this, this.managerURL, 'get', null, true,
        (response) => {
          this.selected = []
          this.customerAgents = response.data.results
          this.totalRows = response.data.count
          this.searchedValue = this.searchVal
          this.tableLoader = false
        }
      )
      this.searchInProgress = false
    },
    openManagerModal (type, data) {
      this.type = type
      if (type === 'add') {
        this.customerAgent = Object.assign({}, {})
      } else {
        this.customerAgent = data
      }
      this.addUserModal = true
    },
    deleteCustomerAgent (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendRequest(false, false, this, `api/v1/users/customer-merchant-agents/${this.deleteData.uid}/`, 'delete', null, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['CAMA'] + i18nData[this.$i18n.locale][' has been deleted successfully!'],
            position: 'top-center'
          })
          this.loadData()
        }
      )
    },
    resetPassword (data) {
      this.resetPass = true
      this.userData = data
    }
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    common.manageLoadWarehouses(this.formatWarehouses, this)
  },
  components: {
    ResetPassword,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination,
    AddCustomerMerchantAgent
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
